<template>
  <div v-if="token" class="install-instructions pb-4">
    <CButton
      color="info"
      shape="square"
      @click="downloadPDF"
    >
      <CIcon name="md-download" size="lg" class="mr-2 my-0" />
      <span>Download Instructions as PDF</span>
    </CButton>
    <section class="my-4">
      <h4>Installation Instructions:</h4>
      <div class="box">
        <ol class="mt-3">
          <li v-for="(instruction, index) in instructions" :key="index">
            {{ instruction.text }}
            <CLink class="btn-link" :href="instruction.link.href" target="_blank">
              {{ instruction.link.label }}
            </CLink>
          </li>
        </ol>
      </div>
    </section>

    <section class="my-4">
      <h4>Activation Codes:</h4>
      <div v-for="property in selectedProperties" :key="property.id" class="box px-4 py-3 mb-3">
        <label>Property Name</label>
        <h5>
          {{ property.name }}
        </h5>
        <label>Activation Code</label>
        <div class="d-flex align-items-center">
          <h5 class="mr-1 mb-0">
            {{ property.activation_code }}
          </h5>
          <CLink class="btn-link" @click="copyActivationCode(property.activation_code)">
            copy code
          </CLink>
        </div>
      </div>
    </section>
    <CAlert color="success" :show.sync="showAlert">
      Copied!
    </CAlert>
  </div>
</template>

<script>
import { pdfMakerMixin } from '@/mixins'

export default {
  name: 'InstallInstructions',
  mixins: [
    pdfMakerMixin
  ],
  model: {
    prop: 'selectedProperties',
    event: 'change'
  },
  props: {
    token: {
      type: String,
      required: true
    },
    selectedProperties: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      showAlert: false
    }
  },
  computed: {
    instructions () {
      const forDesktop = this.selectedProperties.some(property => property.has_scheduled_posting)
      return [
        {
          text: 'Click ',
          link: {
            label: 'here',
            href: forDesktop ? 'https://www.postengine.com/install-desktop'
              : 'https://chrome.google.com/webstore/detail/postengine-by-rooof/oonoojbjhcfdijfcnibboaapchbbmipl'
          }
        },
        {
          text: 'Follow ',
          link: {
            label: 'these instructions',
            href: forDesktop ? 'https://docs.postengine.com/article/305-installing-postengine-for-desktop'
              : 'https://docs.postengine.com/article/228-install-postengine-chromeextension'
          }
        },
        {
          text: "When prompted, enter the activation code listed below. If you're activating for multiple properties, follow ",
          link: {
            label: 'these instructions',
            href: forDesktop ? 'https://docs.postengine.com/article/337-posting-for-multiple-properties-with-postengine-for-desktop'
              : 'https://docs.postengine.com/article/228-install-postengine-chromeextension#multipleproperties'
          }
        },
        {
          text: "When the installation is complete and you're ready to begin posting, click ",
          link: {
            label: 'here',
            href: forDesktop ? 'https://docs.postengine.com/article/306-postengine-for-desktop-how-to-post'
              : 'https://docs.postengine.com/article/353-how-to-post-with-postengine-google-chrome-extension'
          }
        }
      ]
    }
  },
  watch: {
    selectedProperties: {
      handler (properties) {
        if (!properties.length) {
          this.$router.replace({ name: 'PropertySelect', hash: `#${this.token}` })
        }
      },
      immediate: true
    }
  },
  methods: {
    /**
     * Copy the activation code to the clipboard.
     *
     * @param {String} activationCode
     */
    async copyActivationCode (activationCode) {
      await navigator.clipboard.writeText(activationCode)
      this.showAlert = 3
    },
    /**
     * Creates and downloads the installation isntructions and activation codes as PDF
     */
    downloadPDF () {
      const doc = {
        header: function (currentPage, pageCount, pageSize) {
          return [
            {
              absolutePosition: { x: 0, y: 0 },
              canvas: [
                { type: 'rect', x: 0, y: 0, w: pageSize.width, h: 200, color: '#008ffb' }
              ]
            },
            { text: 'Install PostEngine', style: 'title', relativePosition: { y: 70 } }
          ]
        },
        content: [
          {
            text: 'Installation Instructions:',
            style: 'header',
            margin: [0, 190, 0, 10]
          },
          {
            ol: this.instructions.map(instruction => {
              return {
                text: [
                  instruction.text,
                  {
                    text: instruction.link.label,
                    link: instruction.link.href,
                    style: 'link'
                  }
                ]
              }
            })
          },
          {
            text: 'Activation Codes:',
            style: 'header',
            margin: [0, 20, 0, 10]
          },
          {
            table: {
              headerRows: 1,
              widths: [ '*', '*' ],
              body: [
                [
                  { text: 'Property Name', style: 'tableHeader' },
                  { text: 'Activation Code', style: 'tableHeader' }
                ],
                ...this.selectedProperties.map(property => {
                  return [property.name, property.activation_code]
                })
              ]
            },
            layout: 'lightHorizontalLines'
          }
        ],
        styles: {
          title: {
            fontSize: 30,
            bold: true,
            alignment: 'center',
            color: '#fff' // $white
          },
          header: {
            fontSize: 16,
            bold: true
          },
          link: {
            color: '#008ffb', // $info
            decoration: 'underline'
          },
          tableHeader: {
            bold: true
          }
        }
      }

      this.$_pdfMakerMixin_generatePDF(doc, 'Install PostEngine.pdf')
    }
  }
}
</script>

<style lang="scss" scoped>
.install-instructions {
  max-width: 700px;
  margin: 0 auto;

  button {
    border-radius: 3px;
  }

  .box {
    border: 1px solid $gray-200;
    border-radius: 0.25rem;

    h5 {
      font-weight: bold;
      font-size: 1rem;
      color: $gray-800;
    }

    label {
      margin: 0;
      font-size: 0.75rem;
      color: $gray-600;
    }

    .btn-link {
      text-decoration: underline;
      color: $info;
      outline-color: $info-50;
      font-weight: bold;
      &:hover {
        color: $info-50;
      }
      &:active {
        color: $info-25;
      }
    }
  }

  .alert {
    position: fixed;
    max-width: 700px;
    top: 1em;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>

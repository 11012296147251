/**
 * Mixin for the PDFMaker library
 *
 * https://github.com/bpampuch/pdfmake
 * https://pdfmake.github.io/docs/0.1/
 *
 */
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts.js'

const pdfMakerMixin = {
  data () {
    return {
      fonts: {
        proximaNova: {
          normal: 'https://use.typekit.net/af/705e94/00000000000000003b9b3062/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3',
          bold: 'https://use.typekit.net/af/949f99/00000000000000003b9b3068/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3',
          italics: 'https://use.typekit.net/af/5c70f2/00000000000000003b9b3063/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3',
          bolditalics: 'https://use.typekit.net/af/4c4052/00000000000000003b9b3069/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3'
        }
      },
      defaultStyle: {
        font: 'proximaNova',
        color: '#000015' // $black
      }
    }
  },
  created () {
    pdfMake.vfs = pdfFonts.pdfMake.vfs
  },
  methods: {
    /**
     * Creates and downloads PDF based on given document definition
     *
     * @param {Object} documentDefinition - see https://pdfmake.github.io/docs/0.1/document-definition-object/
     * @param {String} fileName - name of the downloaded file
     * @param {Object} fonts - see https://pdfmake.github.io/docs/0.1/fonts/custom-fonts-client-side/
     */
    $_pdfMakerMixin_generatePDF (documentDefinition, fileName = 'Untitled.pdf', fonts = this.fonts) {
      documentDefinition.defaultStyle = this.defaultStyle
      pdfMake.createPdf(documentDefinition, null, fonts).download(fileName)
    }
  }
}

export default pdfMakerMixin
